import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MicrophoneService} from '../services/microphone.service';
import * as rtMedicalConvoAnalyserActions from './-rt-medical-convo-analyser.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {Sentences, Transcription} from './-rt-medical-convo-analyser.types';
import {StopwatchService} from '../services/stopwatch.service';
import {HippocraticumBackendService} from '../services/hippocraticum-backend.service';
import {SessionService} from '../services/session.service';

@Injectable()
export class RtMedicalConvoAnalyserEffects {
    constructor(
      private actions$: Actions,
      private microphoneService: MicrophoneService,
      private stopwatchService: StopwatchService,
      private hippocraticumBackendService: HippocraticumBackendService,
      private sessionService: SessionService
    ) {}

    startSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.startSession),
            switchMap(() =>
                this.microphoneService.requestMicrophoneAccess().pipe(
                    switchMap(() => {
                        this.microphoneService.startStream();
                        return of(rtMedicalConvoAnalyserActions.startStream());
                    }),
                    catchError(error =>
                        of(rtMedicalConvoAnalyserActions.startStreamFailure({error: error.toString()}))
                    )
                )
            )
        )
    );


    stopSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.stopSession),
            switchMap(() =>
                from(this.microphoneService.stopStream()).pipe(
                    map(recordingLength => {
                        if (recordingLength > 60){
                            return rtMedicalConvoAnalyserActions.stopStream()
                        }
                        this.microphoneService.closeSession()
                        this.stopwatchService.stop()
                        this.hippocraticumBackendService.close()
                        return rtMedicalConvoAnalyserActions.showError({errorTitle: 'Recording too short', errorMessage: 'Please record at least 60 seconds'})
                    }),
                    catchError(error => of(rtMedicalConvoAnalyserActions.stopStreamFailure({error: error.toString()})))
                )
            )
        )
    );


    pauseSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.pauseSession),
            switchMap(() => {
                this.microphoneService.pauseStream();
                return of(rtMedicalConvoAnalyserActions.pauseStream());
            })
        )
    );

    resumeSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.resumeSession),
            switchMap(() => {
                this.microphoneService.resumeStream();
                return of(rtMedicalConvoAnalyserActions.resumeStream());
            })
        )
    );

    gotTranscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.gotTranscription),
            map(({transcription}) => {
                let filterAfterNone = false;
                transcription = transcription.reduce<Transcription[]>((acc, t) => {
                    if (filterAfterNone) return acc;
                    if (t.speaker === 'None') {
                        filterAfterNone = true;
                        return acc;
                    }
                    acc.push(t);
                    return acc;
                }, []);
                return rtMedicalConvoAnalyserActions.addTranscription({transcription});
            })
        )
    );

    gotWordGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.gotWordGroup),
            map(({ wordGroups }) => {
                let filterAfterNone = false;

                const modifiedWordGroups = wordGroups.map(wordGroup => {
                    const modifiedSentences = wordGroup.sentences.reduce<Sentences[]>((acc, t) => {
                        if (filterAfterNone) return acc;
                        if (t.speaker === "None") {
                            filterAfterNone = true;
                            return acc;
                        }
                        acc.push(t);
                        return acc;
                    }, []);

                    return { ...wordGroup, sentences: modifiedSentences }; // return a new object instead of modifying the old one
                });

                return rtMedicalConvoAnalyserActions.addWordGroup({ wordGroups: modifiedWordGroups });
            })
        )
    );


    closeSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.closeSession),
            switchMap(() => {
                this.microphoneService.closeSession();
                return of(rtMedicalConvoAnalyserActions.closedSession());
            })
        )
    );

    gotFinalReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rtMedicalConvoAnalyserActions.gotFinalReport),
            switchMap(({finalReport}) => {
                return this.sessionService.storeConsultationSession(finalReport).then(
                    () => console.log('Successfully stored consultation session.'),
                    (error) => console.error('Error while storing consultation session: ', error)
                );
            })
        ),
        {dispatch: false}
    );
}
