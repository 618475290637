import {createReducer, on, Action} from '@ngrx/store';
import {
    CurrentView,
    FinalReport,
    MedicalNote,
    Transcription,
    TranscriptionV2,
    WordGroup
} from './-rt-medical-convo-analyser.types';
import * as RtMedicalConvoAnalyserActions from './-rt-medical-convo-analyser.actions';

export const RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY = 'rtMedicalConvoAnalyser';

export interface RtMedicalConvoAnalyserState {
    currentView: CurrentView;
    stream_online: boolean;
    transcription: Array<Transcription>;
    medicalNote?: MedicalNote;
    clinicalNotesExpanded: boolean;
    selectPreviousSessionsExpanded: boolean;
    finalReport?: FinalReport;
    wordGroups: WordGroup[];
    transcriptionv2?: TranscriptionV2;
    error?: {
        errorTitle: string | undefined;
        errorMessage: string | undefined;
    }
}


export const initialRtMedicalConvoAnalyserState: RtMedicalConvoAnalyserState = {
    currentView: CurrentView.Start,
    stream_online: false,
    transcription: [],
    clinicalNotesExpanded: false,
    selectPreviousSessionsExpanded: true,
    wordGroups: []
}


const reducer = createReducer(
        initialRtMedicalConvoAnalyserState,
        on(RtMedicalConvoAnalyserActions.setCurrentView, (state, {currentView}) => ({
            ...state,
            currentView
        })),
        on(RtMedicalConvoAnalyserActions.startStream, (state) => ({
                ...state,
                stream_online: true
            }
        )),
        on(RtMedicalConvoAnalyserActions.pauseStream, (state) => ({
            ...state,
            stream_online: false
        })),
        on(RtMedicalConvoAnalyserActions.resumeStream, (state) => ({
            ...state,
            stream_online: true
        })),
        on(RtMedicalConvoAnalyserActions.stopStream, (state) => ({
            ...state,
            stream_online: false
        })),
        on(RtMedicalConvoAnalyserActions.addTranscription, (state, {transcription}) => ({
            ...state,
            transcription
        })),
        on(RtMedicalConvoAnalyserActions.gotMedicalNote, (state, {medicalNote}) => ({
                ...state,
                medicalNote
            }
        )),
        on(RtMedicalConvoAnalyserActions.toggleClinicalNotes, (state) => ({
                ...state,
                clinicalNotesExpanded: !state.clinicalNotesExpanded
            })
        ),
        on(RtMedicalConvoAnalyserActions.gotFinalReport, (state, {finalReport}) => ({
                ...state,
                finalReport
            })
        ),
        on(RtMedicalConvoAnalyserActions.closedSession, (_) => initialRtMedicalConvoAnalyserState),
        on(RtMedicalConvoAnalyserActions.gotWordGroup, (state, {wordGroups}) => ({
                ...state,
                wordGroups
            })
        ),
        on(RtMedicalConvoAnalyserActions.setFinalReport, (state, {finalReport}) => ({
                ...state,
                finalReport
            }
        )),
        on(RtMedicalConvoAnalyserActions.showError, (state, {errorTitle, errorMessage}) => ({
                ...state,
                error: {
                    errorTitle,
                    errorMessage
                },
                currentView: CurrentView.SessionError
            })
        ),
        on(RtMedicalConvoAnalyserActions.clearState, (_) => initialRtMedicalConvoAnalyserState),
        on(RtMedicalConvoAnalyserActions.togglePreviousSessions, (state) => ({
                ...state,
                selectPreviousSessionsExpanded: !state.selectPreviousSessionsExpanded
            })
        ),
        on(RtMedicalConvoAnalyserActions.openFinalReport, (state, {finalReport}) => ({
                ...state,
                finalReport,
                currentView: CurrentView.SessionEnd
            })
        )
    )
;

export function rtMedicalConvoAnalyserReducer(
    state: RtMedicalConvoAnalyserState | undefined,
    action: Action
) {
    return reducer(state, action);
}
