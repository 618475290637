import {Component} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {CurrentView} from '../+state/-rt-medical-convo-analyser.types';
import {
    selectClinicalNotesExpanded,
    selectCurrentView,
    selectPreviousSessionsExpanded
} from '../+state/-rt-medical-convo-analyser.selectors';
import {map} from 'rxjs/operators';

@Component({
    selector: 'hippocraticum-ai-frontend-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public readonly CurrentView = CurrentView

    public readonly $clinicalNotesExpanded: Observable<boolean>
    public readonly $currentView: Observable<CurrentView>
    public readonly $previousSessionsExpanded: Observable<boolean>
    public readonly $isPreviousSessionsExpanded: Observable<boolean>

    constructor(private store: Store) {
        this.$clinicalNotesExpanded = this.store.select(selectClinicalNotesExpanded)
        this.$currentView = this.store.select(selectCurrentView)
        this.$previousSessionsExpanded = this.store.select(selectPreviousSessionsExpanded)

        this.$isPreviousSessionsExpanded = combineLatest([
            this.$previousSessionsExpanded,
            this.$currentView
        ]).pipe(
            map(([previousSessionsExpanded, currentView]) => {
                return previousSessionsExpanded && currentView === CurrentView.Start
            })
        );
    }
}
