<div class="report">
    <ng-container *ngIf="$finalReport | async as finalReport">
        <div class="patient-segment" *ngIf="finalReport.medical_note.patient_information">
            <p class="title">Patient</p>
            <div>
                <div class="avatar" src=""></div>
                <div class="patient-data-wrapper">
                    <div>
                        <p>Name</p>
                        <input
                                [value]="finalReport.medical_note.patient_information.full_name"
                                (blur)="updateField('medical_note.patient_information.full_name', $event)">
                    </div>
                    <div>
                        <p>Address</p>
                        <input
                                [value]="finalReport.medical_note.patient_information.address"
                                (blur)="updateField('medical_note.patient_information.address', $event)">
                    </div>
                    <div>
                        <p>Date & Time of consultation</p>
                        <input
                                [value]="finalReport.medical_note.patient_information.consultation_datetime"
                                (blur)="updateField('medical_note.patient_information.consultation_datetime', $event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="segment">
            <h1>Subjective</h1>
        </div>

        <div class="segment chief-complaints" *ngIf="finalReport.medical_note.subjective.chief_complaints">
            <p>Chief Complaint:</p>
            <div>
                <textarea
                        *ngFor="let item of finalReport.medical_note.subjective.chief_complaints; let i = index"
                        rows="1"
                        [value]="item.description"
                        (blur)="updateField('medical_note.subjective.chief_complaints.' + i, $event, 'description')">
                </textarea>
            </div>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.subjective.hpi_description">
            <p>History of Present Illness: </p>
            <div>
                <textarea
                    #autoGrowTextarea
                    rows="1"
                    [value]="finalReport.medical_note.subjective.hpi_description"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.subjective.hpi_description', $event)">
                >
                </textarea>
            </div>
        </div>

        <div class="segment">
            <h1>Objective</h1>
        </div>

        <hippocraticum-ai-frontend-clinical-findings
                [finalReport]="finalReport"></hippocraticum-ai-frontend-clinical-findings>

        <div class="segment" *ngIf="finalReport.medical_note.objective.vital_signs">
            <p>Vital Signs:</p>
            <div>
                <textarea
                    #autoGrowTextarea
                    rows="1"
                    [value]="finalReport.medical_note.objective.vital_signs"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.objective.vital_signs', $event)">
                >
                </textarea>
            </div>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.objective.observed_behavior">
            <p>Observed Behavior:</p>
            <div>
                <textarea
                    #autoGrowTextarea
                    rows="1"
                    [value]="finalReport.medical_note.objective.observed_behavior"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.objective.observed_behaviour', $event)">
                >
                </textarea>
            </div>
        </div>

        <div class="segment">
            <h1>Assessment</h1>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.assessment.differential_diagnosis">
            <p>Differential Diagnosis:</p>
            <div>
                 <textarea
                     #autoGrowTextarea
                     *ngFor="let item of finalReport.medical_note.assessment.differential_diagnosis; let i = index"
                     rows="1"
                     class="p-list"
                     [value]="'&bull; '+item"
                     (input)="adjustHeight($event.target)"
                     (blur)="updateField('medical_note.assessment.differential_diagnosis.' + i, $event, undefined,true)"
                 >
                </textarea>
            </div>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.assessment.preliminary_diagnosis">
            <p>Preliminary Diagnosis:</p>
            <div>
                 <textarea
                     #autoGrowTextarea
                     rows="1"
                     [value]="finalReport.medical_note.assessment.preliminary_diagnosis"
                     (input)="adjustHeight($event.target)"
                     (blur)="updateField('medical_note.assessment.preliminary_diagnosis', $event)">
                 >
                </textarea>
            </div>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.assessment.discussion_summary">
            <p>Discussion Summary:</p>
            <div>
                <textarea
                    #autoGrowTextarea
                    rows="1"
                    [value]="finalReport.medical_note.assessment.discussion_summary"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.assessment.discussion_summary', $event)">
                </textarea>
            </div>
        </div>

        <div class="segment">
            <h1>Plan</h1>
        </div>

        <div class="segment"
             *ngIf="finalReport.medical_note.plan.next_steps && finalReport.medical_note.plan.next_steps.length > 0">
            <p>Next Steps:</p>
            <div>
                <textarea
                    #autoGrowTextarea
                    *ngFor="let item of finalReport.medical_note.plan.next_steps; let i = index"
                    rows="1"
                    [value]="'&bull; '+item"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.plan.next_steps.' + i, $event, undefined, true)"
                    class="p-list"
                >
                </textarea>
            </div>
        </div>

        <div class="segment">
            <h1>Footer</h1>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.footer.conclusion_summary">
            <p>Conclusion/Summary:</p>
            <div>
                <textarea
                    #autoGrowTextarea
                    rows="1"
                    [value]="finalReport.medical_note.footer.conclusion_summary"
                    (input)="adjustHeight($event.target)"
                    (blur)="updateField('medical_note.conclusion.summary', $event)">
                >
                </textarea>
            </div>
        </div>

        <div class="segment" *ngIf="finalReport.medical_note.icd10_references">
            <p>ICD-10-CM Code Reference:</p>
            <div>
               <textarea
                   #autoGrowTextarea
                   *ngFor="let item of finalReport.medical_note.icd10_references; let i = index"
                   rows="1"
                   [value]="item.icd10_code + ' ' + item.description"
                   (input)="adjustHeight($event.target)"
                   class="p-list"
                   disabled
               >
                </textarea>
            </div>
        </div>
    </ng-container>
</div>

<button id="save-button" (click)="generateMedicalNote()">
    <p>Save as PDF</p>
</button>
