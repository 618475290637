import {Component, Input} from '@angular/core';

@Component({
  selector: 'hippocraticum-ai-frontend-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss'],
})
export class IdleModalComponent {
  @Input() grayTheme = false
}
