<div class="wrapper-m" id="clinical-notes-wrapper">
    <div id="header">
        <h3>Clinical notes</h3>
        <div (click)="toggleClinicalNotes()" id="close-button"></div>
    </div>

    <div id="bottom">
        <div class="clinical-notes" id="rt-clinical-note-scroll-tag">
            <div id="hint">
                <div class="logo-grey"></div>
                <h1>Clinical notes</h1>
                <p>All important information will be recorded here automatically</p>
            </div>
            <ng-container *ngIf="($medicalNote | async) as medicalNote">
                <div class="soap-wrapper" *ngIf="medicalNote.subjective">
                    <h2 class="soap-key">Subjective: </h2>
                    <p class="soap-value">{{medicalNote.subjective}}</p>
                </div>
                <div class="soap-wrapper" *ngIf="medicalNote.objective">
                    <h2 class="soap-key">Objective: </h2>
                    <p class="soap-value">{{medicalNote.objective}}</p>
                </div>
                <div class="soap-wrapper" *ngIf="medicalNote.assessment">
                    <h2 class="soap-key">Assessment: </h2>
                    <p class="soap-value">{{medicalNote.assessment}}</p>
                </div>
                <div class="soap-wrapper" *ngIf="medicalNote.plan">
                    <h2 class="soap-key">Plan: </h2>
                    <p class="soap-value">{{medicalNote.plan}}</p>
                </div>
            </ng-container>
        </div>

        <button class="regenerate-notes-button">Regenerate notes</button>
    </div>
</div>
