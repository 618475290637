<div id="feed-wrap">
    <hippocraticum-ai-frontend-idle-modal [grayTheme]="true" ></hippocraticum-ai-frontend-idle-modal>
    <div *ngFor="let item of feedItems" id="scroll-tag">
        <ng-container [ngSwitch]="item.type">

            <ng-container *ngSwitchCase="'feedMessage'">
                <hippocraticum-ai-frontend-feed-message
                        *ngIf="item.sentences"
                        [isUser]="isUserValueValid(item.speaker)"
                        [transcriptions]="item.sentences"
                >
                </hippocraticum-ai-frontend-feed-message>
            </ng-container>

            <ng-container *ngSwitchCase="'sysMessage'">
                <hippocraticum-ai-frontend-feed-sys-message
                        *ngIf="item.message"
                        [message]="item.message">
                </hippocraticum-ai-frontend-feed-sys-message>
            </ng-container>

        </ng-container>
    </div>
</div>
