<div *ngIf="finalReportView" class="final-transcript">
    <div class="client-message" *ngIf="isUser">
        <div class="avatar user"></div>
        <div class="text-bubble">
            <p class="message-time">{{time}}</p>
            <p class="message-content">{{message}}</p>
        </div>
    </div>

    <div class="doctor-message" *ngIf="!isUser">
        <div class="text-bubble">
            <p class="message-time">{{time}}</p>
            <p class="message-content">{{message}}</p>
        </div>
    </div>
</div>

<div *ngIf="!finalReportView" class="in-progress-transcript">
    <div class="client-message" *ngIf="isUser">
        <div class="avatar user"></div>
        <div class="text-bubble">
            <p class="message-time">{{time}}</p>
            <p class="message-content"><span *ngFor="let transcription of transcriptions" [@fadeIn]="transcription.is_complete ? 'default' : 'fadeInState'">{{transcription.text}}&nbsp;</span> </p>
        </div>
    </div>

    <div class="doctor-message" *ngIf="!isUser">
        <div class="text-bubble">
            <p class="message-time">{{time}}</p>
            <p class="message-content"><span *ngFor="let transcription of transcriptions" [@fadeIn]="transcription.is_complete ? 'default' : 'fadeInState'">{{transcription.text}}&nbsp;</span> </p>
        </div>
    </div>
</div>
