import {createAction, props} from '@ngrx/store';
import {CurrentView, FinalReport, MedicalNote, Transcription, WordGroup} from './-rt-medical-convo-analyser.types';

export enum RtMedicalConvoAnalyserActionTypes {
    SET_CURRENT_VIEW = '[RtMedicalConvoAnalyser] Set Current View',
    START_SESSION = '[RtMedicalConvoAnalyser] Start Session',
    START_STREAM = '[RtMedicalConvoAnalyser] Start Stream',
    PAUSE_SESSION = '[RtMedicalConvoAnalyser] Pause Session',
    PAUSE_STREAM = '[RtMedicalConvoAnalyser] Pause Stream',
    RESUME_SESSION = '[RtMedicalConvoAnalyser] Resume Session',
    RESUME_STREAM = '[RtMedicalConvoAnalyser] Resume Stream',
    STOP_SESSION = '[RtMedicalConvoAnalyser] Stop Session',
    STOP_STREAM = '[RtMedicalConvoAnalyser] Stop Stream',
    START_STREAM_FAILURE = '[RtMedicalConvoAnalyser] Start Stream Failure',
    STOP_STREAM_FAILURE = '[RtMedicalConvoAnalyser] Stop Stream Failure',
    GOT_TRANSCRIPTION = '[RtMedicalConvoAnalyser] Got Transcription',
    GOT_WORD_GROUP = '[RtMedicalConvoAnalyser] Got Word Group',
    ADD_WORD_GROUP = '[RtMedicalConvoAnalyser] Add Word Group',
    ADD_TRANSCRIPTION = '[RtMedicalConvoAnalyser] Add Transcription',
    GOT_MEDICAL_NOTE = '[RtMedicalConvoAnalyser] Got Medical Note',
    TOGGLE_CLINICAL_NOTES = '[RtMedicalConvoAnalyser] Toggle Clinical Notes',
    TOGGLE_PREVIOUS_SESSIONS = '[RtMedicalConvoAnalyser] Toggle Previous Sessions',
    GOT_FINAL_REPORT = '[RtMedicalConvoAnalyser] Got Final Report',
    SET_FINAL_REPORT = '[RtMedicalConvoAnalyser] Set Final Report',
    CLOSE_SESSION = '[RtMedicalConvoAnalyser] Close Session',
    CLOSED_SESSION = '[RtMedicalConvoAnalyser] Closed Session',
    SHOW_ERROR = '[RtMedicalConvoAnalyser] Show Error',
    CLEAR_STATE = '[RtMedicalConvoAnalyser] Clear State',
    OPEN_FINAL_REPORT = '[RtMedicalConvoAnalyser] Open Final Report',
}

export const setCurrentView = createAction(
    RtMedicalConvoAnalyserActionTypes.SET_CURRENT_VIEW,
    props<{ currentView: CurrentView }>(),
);

export const startSession = createAction(
    RtMedicalConvoAnalyserActionTypes.START_SESSION,
);

export const pauseSession = createAction(
    RtMedicalConvoAnalyserActionTypes.PAUSE_SESSION,
);

export const resumeSession = createAction(
    RtMedicalConvoAnalyserActionTypes.RESUME_SESSION,
);

export const stopSession = createAction(
    RtMedicalConvoAnalyserActionTypes.STOP_SESSION,
);

export const startStream = createAction(
    RtMedicalConvoAnalyserActionTypes.START_STREAM,
);

export const pauseStream = createAction(
    RtMedicalConvoAnalyserActionTypes.PAUSE_STREAM,
);

export const resumeStream = createAction(
    RtMedicalConvoAnalyserActionTypes.RESUME_STREAM,
);

export const closeSession = createAction(
    RtMedicalConvoAnalyserActionTypes.CLOSE_SESSION,
);

export const stopStream = createAction(
    RtMedicalConvoAnalyserActionTypes.STOP_STREAM
);

export const startStreamFailure = createAction(
    RtMedicalConvoAnalyserActionTypes.START_STREAM_FAILURE,
    props<{ error: string }>(),
);

export const stopStreamFailure = createAction(
    RtMedicalConvoAnalyserActionTypes.STOP_STREAM_FAILURE,
    props<{ error: string }>(),
);

export const gotTranscription = createAction(
    RtMedicalConvoAnalyserActionTypes.GOT_TRANSCRIPTION,
    props<{ transcription: Array<Transcription> }>(),
);

export const addTranscription = createAction(
    RtMedicalConvoAnalyserActionTypes.ADD_TRANSCRIPTION,
    props<{ transcription: Array<Transcription> }>(),
);

export const gotMedicalNote = createAction(
    RtMedicalConvoAnalyserActionTypes.GOT_MEDICAL_NOTE,
    props<{ medicalNote: MedicalNote }>(),
);

export const toggleClinicalNotes = createAction(
    RtMedicalConvoAnalyserActionTypes.TOGGLE_CLINICAL_NOTES
);

export const gotFinalReport = createAction(
    RtMedicalConvoAnalyserActionTypes.GOT_FINAL_REPORT,
    props<{ finalReport: FinalReport }>(),
);

export const closedSession = createAction(
    RtMedicalConvoAnalyserActionTypes.CLOSED_SESSION,
);

export const gotWordGroup = createAction(
    RtMedicalConvoAnalyserActionTypes.GOT_WORD_GROUP,
    props<{ wordGroups: WordGroup[] }>(),
);


export const addWordGroup = createAction(
    RtMedicalConvoAnalyserActionTypes.ADD_WORD_GROUP,
    props<{ wordGroups: WordGroup[] }>(),
);

export const setFinalReport = createAction(
    RtMedicalConvoAnalyserActionTypes.SET_FINAL_REPORT,
    props<{ finalReport: FinalReport }>(),
);

export const showError = createAction(
    RtMedicalConvoAnalyserActionTypes.SHOW_ERROR,
    props<{ errorTitle: string | undefined, errorMessage: string | undefined }>(),
);

export const clearState = createAction(
    RtMedicalConvoAnalyserActionTypes.CLEAR_STATE,
);

export const togglePreviousSessions = createAction(
    RtMedicalConvoAnalyserActionTypes.TOGGLE_PREVIOUS_SESSIONS
);

export const openFinalReport = createAction(
    RtMedicalConvoAnalyserActionTypes.OPEN_FINAL_REPORT,
    props<{ finalReport: FinalReport }>(),
);
