<div class="bottom-action-bar" id="bottom-action-bar">
    <div class="pause-wrapper">
        <ng-container *ngIf="$steamOnline | async; else playSymbol">
            <div class="pause-symbol" (click)="pauseRecording()"></div>
        </ng-container>
        <ng-template #playSymbol>
            <div class="play-symbol" (click)="startRecording()"></div>
        </ng-template>

        <div class="time-wrapper">
            {{formattedTime}} <span>/ 10:00</span>
        </div>
    </div>
    <div class="play-pause-controls">
        <div class="circle"
                [class.unmute]="isSessionNewPausedOrEnd($currentView | async)"
                [hidden]="isSessionInProgress($currentView | async)"
                (click)="toggleRecording()">
                <div class="icon-mic-on"></div>
                <div class="icon-mic-off"></div>
        </div>
        <div id="play-pause-animation" *ngIf="$steamOnline | async">
            <ng-lottie 
                    (click)="toggleRecording()"
                    [options]="options"
                    >
            </ng-lottie>
        </div>
    </div>

    <button class="red-button"
            (click)="stopRecording()"
            [disabled]="!isSessionNewPausedEndOrRunning($currentView | async)">
        End <span>Session</span>
    </button>
</div>
