import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { selectCurrentView } from '../../+state/-rt-medical-convo-analyser.selectors';
import { CurrentView } from '../../+state/-rt-medical-convo-analyser.types';
import { Store } from '@ngrx/store';

@Component({
  selector: 'hippocraticum-ai-frontend-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentComponent {
  CurrentView = CurrentView;
  public $currentView: Observable<CurrentView>;

  constructor(private store: Store) {
    this.$currentView = this.store.select(selectCurrentView);
  }

  isSessionInProgressPausedOrEnd(currentView: CurrentView | null): boolean {
    return currentView === CurrentView.SessionInProgress ||
        currentView === CurrentView.SessionPaused ||
        currentView === CurrentView.SessionEnd;
  }
}
