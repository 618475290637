import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hippocraticum-ai-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ngOnInit() {
    this.adjustViewportHeight();
    window.addEventListener('resize', this.adjustViewportHeight);
  }

  adjustViewportHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
}
