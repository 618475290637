import { Route } from '@angular/router';
import { ShellComponent } from './container/shell/shell.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: ShellComponent,
        children: [
            {
              path: 'rt-medical-convo-analyser',
              loadChildren: () => import('@hippocraticum-ai-frontend/rt-medical-convo-analyser').then(m => m.RtMedicalConvoAnalyserModule)
            },
            {
                path: "**",
                redirectTo: "rt-medical-convo-analyser"
            }
            ]
    }
];
