import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY,
  RtMedicalConvoAnalyserState,
} from './-rt-medical-convo-analyser.reducer';

// Lookup the 'RtMedicalConvoAnalyser' feature state managed by NgRx
export const selectRtMedicalConvoAnalyserState =
  createFeatureSelector<RtMedicalConvoAnalyserState>(
    RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY
  );

export const selectCurrentView = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.currentView
);

export const selectTranscription = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.transcription
);

export const selectWordGroups = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.wordGroups
);

export const selectMedicalNote = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.medicalNote
);

export const selectClinicalNotesExpanded = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.clinicalNotesExpanded
);

export const selectStreamOnline = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.stream_online
);

export const selectFinalReport = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.finalReport
);

export const selectError = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.error
);

export const selectPreviousSessionsExpanded = createSelector(
    selectRtMedicalConvoAnalyserState,
    (state: RtMedicalConvoAnalyserState) => state.selectPreviousSessionsExpanded
);
