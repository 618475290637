import { Injectable } from '@angular/core';
import {from, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HippocraticumBackendService} from './hippocraticum-backend.service';

@Injectable({
    providedIn: 'root'
})
export class MicrophoneService {

    private stream: MediaStream | null = null;

    constructor(private hippocraticumBackendService: HippocraticumBackendService) { }

    requestMicrophoneAccess(): Observable<MediaStream> {
        return from(navigator.mediaDevices.getUserMedia({ audio: true })).pipe(
            tap(stream => this.stream = stream),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    startStream() {
        if (this.stream) {
            this.hippocraticumBackendService.start(this.stream);
        }
    }

    stopStream() {
        if (this.stream) {
            return this.hippocraticumBackendService.stop();
            // return recording length
        } else {
            return Promise.resolve(0);
        }
    }

    closeSession() {
        this.hippocraticumBackendService.close();
    }

    pauseStream() {
        if (this.stream) {
            this.hippocraticumBackendService.pause();
        }
    }

    resumeStream() {
        if (this.stream) {
            this.hippocraticumBackendService.resume();
        }
    }
}
