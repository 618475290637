<div class="wrapper h-full" id="final-notes-wrapper">
    <div class="close-action-area" (click)="exitSession()"></div>
    <div class="final-clinical-notes h-full">
        <div class="header">
            <h3>Session #1</h3>
            <div id="close-button" (click)="exitSession()"></div>
        </div>

        <ng-container *ngIf="$finalReport | async as finalReport">
            <div id="tab-navigator">
                <div class="tab" [class.active]="currentTab === CurrentTab.Report || currentTab === CurrentTab.Loading" (click)="onTabClick(CurrentTab.Report)">
                    <p>Report</p>
                </div>

                <div class="tab" [class.active]="currentTab === CurrentTab.Transcript"  (click)="onTabClick(CurrentTab.Transcript)">
                    <p>Transcript</p>
                </div>
            </div>

            <hippocraticum-ai-frontend-report *ngIf="currentTab === CurrentTab.Report"></hippocraticum-ai-frontend-report>

            <div class="final-transcript" *ngIf="currentTab === CurrentTab.Transcript">
                <div class="transcript-wrapper">
                    <hippocraticum-ai-frontend-feed-message
                            *ngFor="let item of finalReport.transcription"
                            [isUser]="processUserValue(item.speaker)"
                            [message]="item.text"
                            [finalReportView]="true"
                    >
                    </hippocraticum-ai-frontend-feed-message>
                </div>
            </div>
        </ng-container>

        <div id="loading-scene" *ngIf="!($finalReport | async)">
            <div>
                <ng-lottie [options]="options" height="100px"></ng-lottie>
                <h2>Preparing Medical Analysis</h2>
                <p>Your medical report is being crafted. <br>Thank you for your patience.</p>
            </div>
        </div>
    </div>
</div>

