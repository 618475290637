import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AppComponent } from './app/app.component';
import { RouterModule } from '@angular/router';
import { featuresRtMedicalConvoAnalyserRoutes } from './lib.routes';
import { ComponentsModule } from '@hippocraticum-ai-frontend/components';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromRtMedicalConvoAnalyser from './+state/-rt-medical-convo-analyser.reducer';
import { RtMedicalConvoAnalyserEffects } from './+state/-rt-medical-convo-analyser.effects';
import { BottomActionWrapperComponent } from './components/bottom-action-wrapper/bottom-action-wrapper.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { HeaderComponent } from './segments/header/header.component';
import { ContentComponent } from './segments/content/content.component';
import { FooterComponent } from './segments/footer/footer.component';
import { IdleModalComponent } from './components/idle-modal/idle-modal.component';
import { SidebarComponent } from './segments/sidebar/sidebar.component';
import { FeedComponent } from './components/feed/feed.component';
import { FeedMessageComponent } from './components/feed-message/feed-message.component';
import { FeedSysMessageComponent } from './components/feed-sys-message/feed-sys-message.component';
import { UniqueTranscriptionPipe } from './pipes/unique-transcription.pipe';
import { FinalReportViewComponent } from './segments/final-report-view/final-report-view.component';
import { LottieModule } from 'ngx-lottie';
import { HttpClientModule } from '@angular/common/http';
import { ReportComponent } from './segments/report/report.component';
import { ClinicalFindingsComponent } from './segments/clinical-findings/clinical-findings.component';
import { ErrorComponent } from './pages/error/error.component';
import { CookieService } from 'ngx-cookie-service';
import {PreviousSessionsComponent} from './components/previous-sessions/previous-sessions.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(featuresRtMedicalConvoAnalyserRoutes),
    ComponentsModule,
    StoreModule.forFeature(
      fromRtMedicalConvoAnalyser.RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY,
      fromRtMedicalConvoAnalyser.rtMedicalConvoAnalyserReducer
    ),
    EffectsModule.forFeature([RtMedicalConvoAnalyserEffects]),
    NgOptimizedImage,
    LottieModule,
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    BottomActionWrapperComponent,
    WelcomeModalComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    IdleModalComponent,
    SidebarComponent,
    FeedComponent,
    FeedMessageComponent,
    FeedSysMessageComponent,
    UniqueTranscriptionPipe,
    FinalReportViewComponent,
    ReportComponent,
    ClinicalFindingsComponent,
    ErrorComponent,
    PreviousSessionsComponent,
  ],
  providers: [UniqueTranscriptionPipe, CookieService],
})
export class RtMedicalConvoAnalyserModule {}
