<div id="header">
    <div class="right">
        <div class="logo" role="img" aria-label="Hippocraticum Logo"></div>
        <h1>Hippocraticum AI</h1>
    </div>

    <div>
        <div id="clipboard-icon" *ngIf="($currentView | async) !== CurrentView.Start" (click)="toggleClinicalNotes()">
            <div role="img" aria-label="Clipboard Icon"></div>
        </div>
        <div id="previous-sessions-icon" *ngIf="($currentView | async) === CurrentView.Start" (click)="togglePreviousSessions()">
            <div role="img" aria-label="Previous Sessions Icon"></div>
        </div>
    </div>
</div>


