<ng-container *ngIf="$currentView | async as currentView">

    <hippocraticum-ai-frontend-welcome-modal *ngIf="currentView === CurrentView.Start"></hippocraticum-ai-frontend-welcome-modal>

    <hippocraticum-ai-frontend-idle-modal *ngIf="currentView === CurrentView.SessionInit"></hippocraticum-ai-frontend-idle-modal>

    <ng-container *ngIf="isSessionInProgressPausedOrEnd(currentView)">
        <hippocraticum-ai-frontend-feed></hippocraticum-ai-frontend-feed>
    </ng-container>

    <hippocraticum-ai-frontend-error *ngIf="currentView === CurrentView.SessionError"></hippocraticum-ai-frontend-error>

</ng-container>
