<div id="popup">
    <div id="mute-symbol" alt="mute"></div>
    <h2>Experience Hippocraticum AI</h2>
    <p>Discover the future of medical note generation.</p>
    <button class="red-button" (click)="startSession()">Begin the Demo</button>
</div>

<div class="version-section">
    <p>version {{version}}</p>
</div>
