import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranscriptionComponent } from './transcription/transcription.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TranscriptionComponent],
  exports: [TranscriptionComponent],
})
export class ComponentsModule {}
