import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {ShellComponent} from './container/shell/shell.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {
    RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY,
    rtMedicalConvoAnalyserReducer
} from '@hippocraticum-ai-frontend/rt-medical-convo-analyser';
import {CommonModule} from '@angular/common';
import player from 'lottie-web';
import {LottieModule} from 'ngx-lottie';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../../../../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [AppComponent, ShellComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {initialNavigation: 'enabledBlocking'}),
        StoreModule.forRoot(
            {
                [RT_MEDICAL_CONVO_ANALYSER_FEATURE_KEY]: rtMedicalConvoAnalyserReducer,
            },
            {
                metaReducers: [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            }
        ),
        StoreDevtoolsModule.instrument({maxAge: 40, trace: true}),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        LottieModule.forRoot({player: playerFactory}),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
