import { Component } from '@angular/core';
import {Observable} from 'rxjs';
import {MedicalNote} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import {selectMedicalNote} from '../../+state/-rt-medical-convo-analyser.selectors';
import * as RtMedicalConvoAnalyserActions from '../../+state/-rt-medical-convo-analyser.actions';

@Component({
  selector: 'hippocraticum-ai-frontend-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  $medicalNote: Observable<MedicalNote | undefined>;

  constructor(private store: Store) {
    this.$medicalNote = this.store.select(selectMedicalNote);

    this.$medicalNote.subscribe((medicalNote) => {
        if (medicalNote) {
            this.scrollToBottom();
        }
    });
  }

  toggleClinicalNotes() {
    this.store.dispatch(RtMedicalConvoAnalyserActions.toggleClinicalNotes())
  }

  scrollToBottom() {
    setTimeout(() => {
          const element = document.getElementById("rt-clinical-note-scroll-tag");
          if (element) {
            element.scrollTop = element.scrollHeight;
          } else {
              // TODO: Sub to expanded state and scroll to bottom when expanded
              // console.error(`Element with id scrollable not found.`);
          }
        }
        , 50)
  }
}
