import { Pipe, PipeTransform } from '@angular/core';
import {Transcription} from '../+state/-rt-medical-convo-analyser.types';

@Pipe({
  name: 'uniqueTranscription',
})
export class UniqueTranscriptionPipe implements PipeTransform {
  transform(transcriptions: Transcription[], existingFeedItems: any[]): Transcription[] {
    return transcriptions.reduce((unique: Transcription[], item: Transcription) => {
      if (!existingFeedItems.some(feedItem => feedItem.id === item.id)) {
        unique.push(item);
      }
      return unique;
    }, []);
  }
}
