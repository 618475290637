import {Component, Input} from '@angular/core';
import {FinalReport} from '../../+state/-rt-medical-convo-analyser.types';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {setFinalReport} from "../../+state/-rt-medical-convo-analyser.actions";

@Component({
    selector: 'hippocraticum-ai-frontend-clinical-findings',
    templateUrl: './clinical-findings.component.html',
    styleUrls: ['./clinical-findings.component.scss']
})
export class ClinicalFindingsComponent {

    @Input() finalReport!: FinalReport;

    constructor(private store: Store) {
    }

    updateField(path: string, value: Event, keyToUpdate?: string, isBulletPoint = false): void {
        if (!this.finalReport) {
            return;
        }

        const target = value.target as HTMLInputElement;
        let valueToUpdate = target.value;

        if (isBulletPoint) {
            valueToUpdate = valueToUpdate.replace(/^•\s/, ""); // Remove the bullet point and following whitespace
        }

        const updatedFinalReport = _.cloneDeep(this.finalReport); // clone the current report

        // Set the new value based on whether keyToUpdate is provided
        if (keyToUpdate) {
            _.set(updatedFinalReport, path + '.' + keyToUpdate, valueToUpdate);
        } else {
            _.set(updatedFinalReport, path, valueToUpdate);
        }

        this.store.dispatch(setFinalReport({finalReport: updatedFinalReport})); // assuming you have an update action for the report
    }

}
