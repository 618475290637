<div class="sidebar-container" [class.expanded]="$clinicalNotesExpanded | async" [class.full-expanded]="$isPreviousSessionsExpanded | async">
    <div class="wrapper">
        <hippocraticum-ai-frontend-header></hippocraticum-ai-frontend-header>
        <hippocraticum-ai-frontend-content id="scrollable"></hippocraticum-ai-frontend-content>
        <hippocraticum-ai-frontend-footer></hippocraticum-ai-frontend-footer>
    </div>
    <div class="sidebar-wrapper">
        <ng-container *ngIf="$clinicalNotesExpanded | async; let isExpanded">
            <hippocraticum-ai-frontend-sidebar *ngIf="isExpanded"></hippocraticum-ai-frontend-sidebar>
        </ng-container>
        <ng-container *ngIf="$isPreviousSessionsExpanded | async; let isExpanded">
            <hippocraticum-ai-frontend-previous-sessions *ngIf="isExpanded"></hippocraticum-ai-frontend-previous-sessions>
        </ng-container>
    </div>

    <ng-container *ngIf="$currentView | async; let view">
        <hippocraticum-ai-frontend-final-report-view *ngIf="view === CurrentView.SessionEnd"></hippocraticum-ai-frontend-final-report-view>
    </ng-container>
</div>
