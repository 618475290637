export enum CurrentView {
    Start = 'start',
    SessionInit = 'session-init',
    SessionInProgress = 'session-in-progress',
    SessionPaused = 'session-paused',
    SessionEnd = 'session-end',
    SessionError = 'session-error',
}

export interface Transcription {
    id: string;
    text: string;
    speaker: string;
    is_complete: boolean;
};

export interface MedicalNote {
    subjective: string;
    objective: string;
    assessment: string;
    plan: string;
}
interface PatientInformation {
    full_name: string;
    address: string;
    consultation_datetime: string;
}

interface ChiefComplaint {
    description: string;
}

interface Subjective {
    chief_complaints: ChiefComplaint[];
    hpi_description: string;
}

interface ClinicalFinding {
    icd10_code: string;
    description: string;
}

interface Objective {
    clinical_findings: ClinicalFinding[];
    vital_signs: string;
    observed_behavior: string;
}

interface Assessment {
    differential_diagnosis: string[];
    preliminary_diagnosis: string;
    discussion_summary: string;
}

interface Plan {
    next_steps: string[];
    patient_education: string;
}

interface Footer {
    conclusion_summary: string;
}

interface ICD10Reference {
    icd10_code: string;
    description: string;
}

interface MedicalReport {
    patient_information: PatientInformation;
    subjective: Subjective;
    objective: Objective;
    assessment: Assessment;
    plan: Plan;
    footer: Footer;
    icd10_references: ICD10Reference[];
}


export interface FinalReport {
    transcription: Array<Transcription>;
    medical_note: MedicalReport;
}


export interface Sentences {
    sentence: string;
    speaker?: "doctor" | "patient" | "None";
}



export interface WordGroup {
    group_id: string;
    start_time: number;
    end_time: number;
    sentences: Sentences[];
    is_complete: boolean;
}


// Assuming SentenceState and Speaker are enums, define them as TypeScript enums or types
export enum SentenceState {
    UNPROCESSED = "unprocessed",
    PREPROCESSED = "preprocessed",
    PROCESSED = "processed",
}

enum Speaker {
    DOCTOR = "doctor",
    PATIENT = "patient",
}

export interface Sentence {
    id: number;
    text: string;
    englishText: string;
    state: SentenceState;
    speaker: Speaker;
}

export interface TranscriptionV2 {
    sentences: Sentence[];
    language: string;
}
