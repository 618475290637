import {Component, Input} from '@angular/core';

@Component({
  selector: 'hippocraticum-ai-frontend-feed-sys-message',
  templateUrl: './feed-sys-message.component.html',
  styleUrls: ['./feed-sys-message.component.scss'],
})
export class FeedSysMessageComponent {
  timestamp!: string;
  @Input() message!: string;

  constructor() {
    this.getCurrentTime();
  }

  getCurrentTime() {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    this.timestamp = `${formattedDate}, ${formattedTime}`;
  }
}
