<div class="wrapper-m">
    <div class="title" *ngIf="!isMobile">
        <h1>Previous sessions</h1>
    </div>

    <div class="title-mobile" *ngIf="isMobile">
        <h1>Previous sessions</h1>
        <div class="close-button" (click)="togglePreviousSessions()"></div>
    </div>

    <div *ngFor="let previousSession of previousSessions" class="session">
        <div class="content" (click)="ViewSession(previousSession.id)">
            <div class="avatar"></div>
            <div class="patient-data">
                <div class="set">
                    <h2>Name</h2>
                    <p>{{previousSession.medical_note.patient_information.full_name}}</p>
                </div>
                <div class="set">
                    <h2>Address</h2>
                    <p>{{previousSession.medical_note.patient_information.address}}</p>
                </div>
                <div class="set">
                    <h2>Date & Time</h2>
                    <p>{{previousSession.medical_note.patient_information.consultation_datetime}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
