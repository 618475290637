import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { stopSession } from '../+state/-rt-medical-convo-analyser.actions';

@Injectable({
  providedIn: 'root'
})
export class StopwatchService {
  private count = 0;
  private intervalSubscription!: Subscription;

  time$: Observable<number>;
  private timeSubject: Subject<number> = new Subject<number>();

  // Maximum time in seconds (10 minutes)
  private maxTime = 600;

  constructor(private store: Store) {
    this.time$ = this.timeSubject.asObservable();
  }

  start() {
    // Prevents starting if the timer is already running
    if (this.intervalSubscription && !this.intervalSubscription.closed) {
      return;
    }

    this.intervalSubscription = timer(0, 1000).subscribe(() => {
      this.count++;
      this.timeSubject.next(this.count);
      if (this.count >= this.maxTime) {
        console.debug("max time reached")
        this.store.dispatch(stopSession());
        this.stop();
      }
    });
  }

  pause() {
    this.intervalSubscription?.unsubscribe();
  }

  stop() {
    this.intervalSubscription?.unsubscribe();
    this.count = 0;
    this.timeSubject.next(this.count);
  }
}
